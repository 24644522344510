import { Image } from "@chakra-ui/image";
import { Stack, Text } from "@chakra-ui/layout";
import React, { lazy, Suspense, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import urlWrong from "./assets/url_not_found.svg";
import { useStateValue } from "./context/StateProvider";
import CommonLockCode from "./pages/CommonLockCode";
import ScrollToTop from "./utils/ScrollToTop";
import { useEffect } from "react";
import { springBaseUrl } from "./utils/constants";
import axios from "axios";
const Feedback = lazy(() => import("./pages/Feedback"));
const EarlyCheckInUpSellStep = lazy(() => import("./pages/EarlyCheckInUpSell"));
const LateCheckOutUpSellStep = lazy(() => import("./pages/LateCheckOutUpSell"));
const CartCheckOut = lazy(() => import("./pages/CartCheckOut"));
const CartSuccessConfirmation = lazy(() =>
  import("./pages/CartSuccessConfirmation")
);
const Invoice = lazy(() => import("./components/Invoice"));
const OutletList = lazy(() => import("./pages/OutletList"));
const OutletMenuList = lazy(() => import("./pages/OutletMenuList"));
const SelfRoomUpgrade = lazy(() => import("./pages/SelfRoomUpgrade"));
const UpgradeOptionsForRoom = lazy(() =>
  import("./pages/UpgradeOptionsForRoom")
);
const RoomUpgradeUpsell = lazy(() => import("./pages/RoomUpgradeUpsell"));
const AccessCode = lazy(() => import("./pages/AccessCode"));
const CheckIn = lazy(() => import("./pages/CheckIn"));
const CheckOut = lazy(() => import("./pages/CheckOut"));
const HomePage = lazy(() => import("./pages/HomePage"));
const Itinerary = lazy(() => import("./pages/Itinerary"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const PendingStatus = lazy(() => import("./pages/PendingStatus"));
const SelfCheckIn = lazy(() => import("./pages/SelfCheckIn"));
const SuccessCheckInCheckOut = lazy(() =>
  import("./pages/SuccessCheckInCheckOut")
);
const SuccessPage = lazy(() => import("./pages/SuccessPage"));
const ErrorBoundary = lazy(() => import("./utils/ErrorBoundary"));
const FrontDeskKiosk = lazy(() => import("./pages/FrontDeskKiosk"));
const WalkInBookingConfirmation = lazy(() =>
  import("./pages/WalkInBookingConfirmation")
);
const envType = process.env.REACT_APP_ENV;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [{ bookingId, hotelId }, dispatch] = useStateValue();

  // const [hotelId] = useState(params["hotel_id"] ? params["hotel_id"] : hotelID || "");
  // const [bookingId] = useState(params["booking_id"] ? params["booking_id"] : bookingID || "");

  const [utmSource] = useState(
    params["utm_source"] ? params["utm_source"] : ""
  );
  localStorage.setItem("utmSource", utmSource);

  const [actionType, setActionType] = useState(
    params["action"] ? params["action"] : null
  );

  const removeAction = () => {
    setActionType(null);
  };
  const [whiteLabelled, setWhiteLabelled] = useState("");

  useEffect(() => {
    axios
      .get(
        `${springBaseUrl}/user/groupBranding?hostUrl=${window.location.hostname}`
      )
      .then((res) => {
        if (res?.data) {
          if (res?.data?.status !== null) {
            setWhiteLabelled(res?.data?.status);
          }
          if (res?.data?.faviconUrl) {
            const favicon16 = document.getElementById("favicon16");
            const favicon32 = document.getElementById("favicon32");
            favicon16.href = res?.data?.faviconUrl;
            favicon32.href = res?.data?.faviconUrl;
          }
        }
      });
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Stack className="app">
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Switch>
                <Route path="/feedback" component={Feedback} />
                <Route
                  path="/cartSuccessConfirmation"
                  component={CartSuccessConfirmation}
                />
                <Route path="/cartCheckout" component={CartCheckOut} />
                <Route
                  path="/ask-question/lateCheckOut"
                  component={LateCheckOutUpSellStep}
                />
                <Route
                  path="/ask-question/earlyCheckIn"
                  component={EarlyCheckInUpSellStep}
                />
                <Route
                  path="/ask-question/roomUpgrde"
                  component={RoomUpgradeUpsell}
                />
                <Route path="/self-check-in" component={SelfCheckIn} />
                <Route
                  path="/self-room-upgrade/:resId"
                  component={UpgradeOptionsForRoom}
                />

                <Route path="/self-room-upgrade" component={SelfRoomUpgrade} />
                <Route path="/order-online" component={OutletList} />
                <Route path="/:shop_id/menu-item" component={OutletMenuList} />
                <Route path="/invoice/:order_id" component={Invoice} />
                <Route path="/check-in" component={CheckIn} />
                <Route path="/success" component={SuccessPage} />
                <Route
                  path="/:type/success"
                  component={SuccessCheckInCheckOut}
                />
                <Route path="/confirmation_pending" component={PendingStatus} />
                <Route path="/check-out" component={CheckOut} />
                <Route path="/itinerary" component={Itinerary} />
                <Route path="/access_code" component={AccessCode} />
                <Route path="/common-area-codes" component={CommonLockCode} />
                <Route
                  path="/front-desk-kiosk/:hotel_id"
                  component={FrontDeskKiosk}
                />
                <Route
                  path="/booking-confirmation/:booking_id"
                  component={WalkInBookingConfirmation}
                />
                <Route path="/booking/:booking_id">
                  <HomePage action={actionType} removeAction={removeAction} />
                </Route>
                <Route path="/hotel/:hotel_id">
                  <LandingPage />
                </Route>
                <Route
                  path="/"
                  render={() => {
                    const url = new URL(window.location.href);
                    const booking_id = url.searchParams.get("booking_id");
                    console.log(booking_id);
                    booking_id !== null ? (
                      envType === "PROD" ? (
                        window.open(
                          `https://selfservice.stayflexi.com/booking/${booking_id}`,
                          "_self"
                        )
                      ) : (
                        window.open(
                          `https://beta.selfservice.stayflexi.com/booking/${booking_id}`,
                          "_self"
                        )
                      )
                    ) : (
                      <Stack justify="center" align="center" dir="column">
                        <Image width={"250px"} mb="5" src={urlWrong} alt="" />
                        <Text color="blackAlpha.600">
                          Please enter a valid url.
                        </Text>
                      </Stack>
                    );
                  }}
                >
                  {/* {((hotelId === "" && bookingId === "") || (hotelId.length !== 5 && bookingId.length !== 21)) && ( */}
                  {/* <Stack justify="center" align="center" dir="column">
                      <Image width={"250px"} mb="5" src={urlWrong} alt="" />
                      <Text color="blackAlpha.600">Please enter a valid url.</Text>
                    </Stack> */}
                  {/* )} */}
                </Route>
              </Switch>
              <ScrollToTop />
            </Router>
          </QueryClientProvider>
        </ErrorBoundary>
      </Stack>
    </Suspense>
  );
}

export default App;
